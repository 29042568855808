import axios from 'axios'
import router from "@/router";

export const http = axios.create({
  baseURL: process.env.VUE_APP_BACK_HOST
})

http.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  if (error?.response?.status === 401) {
    localStorage.removeItem('token')
    router.push('/login')
  }
  return Promise.reject(error)
})

http.interceptors.request.use(function (config) {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
  return config
})
