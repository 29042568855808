import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
    path: '/login',
    name: 'login',
    component: () => import('@/components/pages/login/LoginPage.vue')
}, {
    path: '/',
    redirect: '/users',
    component: () => import('@/components/layouts/baseLayout/BaseLayout.vue'),
    children: [{
        path: '/categories',
        name: 'categories',
        component: () => import('@/components/pages/categories/CategoriesPage.vue')
    }, {
        path: '/users',
        name: 'users',
        meta: { title: 'Users' },
        component: () => import('@/components/pages/users/UsersPage.vue')
    }, {
        path: '/posts',
        name: 'posts',
        component: () => import('@/components/pages/posts/PostsPage.vue')
    }, {
        path: '/posts/:postId',
        name: 'post-editor',
        component: () => import('@/components/pages/posts/editor/PostEditor.vue')
    }]
}]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    next()
})

export default router
