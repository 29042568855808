import {http} from "@/api/axios.config";
import {defaultError} from "@/utils/default-error";
import {categoriesStore} from "@/api/categories/categories.store";
import {ElMessage} from "element-plus";


export default {
    async getCategories() {
        try {
            const data = await http.get(`/api/v1/categories`, {
                params: { limit: 10000 }
            })
            categoriesStore.items = data.data
        } catch (error) {
            console.log(error)
            defaultError(error)
            throw error
        }
    },
    async createCategory(category) {
        try {
            await http.put(`/api/v1/admin/category`, category)
            await this.getCategories()
            ElMessage.success({ message: 'Successfully created' })
        } catch (error) {
            console.log(error)
            defaultError(error)
            throw error
        }
    },
    async updateCategory(categoryId, category) {
        try {
            await http.put(`/api/v1/admin/category/${categoryId}`, category)
            await this.getCategories()
            ElMessage.success({ message: 'Successfully updated' })
        } catch (error) {
            console.log(error)
            defaultError(error)
            throw error
        }
    },
    async deleteCategory(categoryId) {
        try {
            await http.delete(`/api/v1/admin/category/${categoryId}`)
            await this.getCategories()
            ElMessage.success({ message: 'Successfully deleted' })
        } catch (error) {
            console.log(error)
            defaultError(error)
            throw error
        }
    }
}