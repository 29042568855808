import {http} from "@/api/axios.config";
import {defaultError} from "@/utils/default-error";
import {userStore} from "@/api/user/user.store";
import router from "@/router";

export default {
    async login(requestData) {
        try {
            const data = await http.post(`/api/v1/user/login`, requestData)
            userStore.user = data
            router.push('/')
        } catch (error) {
            console.log(error)
            defaultError(error)
            throw error
        }
    },
    async getUser() {
        try {
            const data = await http.get(`/api/v1/user/me`)
            userStore.user = data
        } catch (error) {
            console.log(error)
            throw error
        }
    },
    async logout() {
        try {
            await http.post('/api/v1/user/logout')
            router.push('/login')
        } catch (e) {
            console.log(e)
        }
    }
}
