<template>
  <div class="app">
    <router-view v-slot="{ Component }">
      <transition name="el-fade-in" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import categoriesService from "@/api/categories/categories.service";
import userService from "@/api/user/user.service";

categoriesService.getCategories()
userService.getUser()
</script>
